import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import scrollToComponent from 'react-scroll-to-component'

import styles from './styles.module.css'

class Header extends Component {
  render() {
    const { content, loading, scrollToRef } = this.props
    return (
      <div className={styles.root}>
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className={styles.titleContainer}
        >
          {content.headerPicture.value.childImageSharp?
            <Img
              critical
              className={styles.image}
              fluid={content.headerPicture.value.childImageSharp.fluid}
              alt="Header picture"
              onStartLoad={loading.register}
              onLoad={loading.notify}
            />:
            <div className={styles.videoContainer}>
              <video 
              autoPlay
              loop
              muted
              className={styles.image}>
                <source
                  src={content.headerPicture.value.publicURL}
                  type="video/mp4"
                  />
              </video>
            </div>
          }
          <div className={styles.title}
            dangerouslySetInnerHTML={{
              __html: content.primary.value.toUpperCase(),
            }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: content.secondary.value.childMarkdownRemark.html.toUpperCase(),
            }}
          />
          <div
            onClick={() =>
              scrollToComponent(scrollToRef.current, {
                offset: -46,
                align: 'middle',
                duration: 500,
              })
            }
          >
            <ReactSVG
              className={styles.dropdownIcon}
              src={content.scrolldownIcon.value.publicURL}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Header

export const query = graphql`
  fragment Header on CockpitHeader {
      primary {
        value
      }
      secondary {
        value {
          childMarkdownRemark {
            html
          }
        }
      }
      scrolldownIcon {
        type
        value {
          publicURL      
        }
      }
      headerPicture {
        value {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
          publicURL
        }
    }
}
`
