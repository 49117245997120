import classNames from 'classnames'
import eventEmitter from '../../eventEmitter'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { isMobile } from "../../shared"

import styles from './styles.module.css'

const { navigate } = require('gatsby')

class Research extends Component {

    componentDidMount() {
        this.setState({ isMobile: isMobile() })
        window.addEventListener('resize', this.updateViewport)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateViewport)
        eventEmitter.off()
    }

    updateViewport = () => {
        const mobile = isMobile()
        if (this.state.isMobile !== mobile) {
            this.setState({ isMobile: mobile })
        }
        if (!mobile && this.state.fullscreen) {
            this.setState({ fullscreen: false })
        }
    }
    render() {
        const { content, loading } = this.props

        return (
            <div className={styles.root}>
                <div
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    className={styles.researchSection}>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}
                            dangerouslySetInnerHTML={{
                                __html: content.primary.value.toUpperCase(),
                            }}
                        />
                        <div className={styles.secondaryTitle}
                            dangerouslySetInnerHTML={{
                                __html: content.secondary.value.toUpperCase(),
                            }}
                        />
                        <div className={styles.lineSection}>
                            <div className={classNames(styles.line, styles.white, styles.whiteBackground)} />
                        </div>
                    </div>
                    <div className={styles.descriptionContainer}>
                        <div className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: content.description.value,
                            }}
                        />
                        <div className={styles.imageSection}>
                            <div className={styles.imageSubsection}>
                                <Img
                                    critical
                                    className={styles.image}
                                    fluid={content.image.value.childImageSharp.fluid}
                                    alt="Research picture"
                                    onStartLoad={loading.register}
                                    onLoad={loading.notify}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.lineSection}>
                        <div className={classNames(styles.line, styles.white, styles.whiteBackground)} />
                        <div className={classNames(styles.button, styles.white, styles.linkWhite)} onClick={this.onClick}>
                            <FormattedMessage id={'Read more'} defaultMessage={'Read more'} />
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    onClick = () => {
        if (this.state.isMobile) {
            navigate('/research')
        } else {
            eventEmitter.emit('openTray', 1)
        }
    }
}

export default Research

export const query = graphql`
  fragment Research on CockpitResearchSection {
        primary {
            value
        }
        secondary {
            value
        }
        description {
            value
        }
        image {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
        }
      }

`