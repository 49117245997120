import React, { Component } from "react";

import styles from './styles.module.css'
class Footer extends Component {
    render() {
        const date = new Date()
        return <div className={styles.footer}>
            Copyright © {date.getFullYear()} goodhead.ca
        </div>
    }
}

export default Footer