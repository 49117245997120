import classNames from 'classnames'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import { isMobile } from "../../shared"
import eventEmitter from '../../eventEmitter'

import styles from './styles.module.css'

const { navigate } = require('gatsby')

class Summary extends Component {

  componentDidMount() {
    this.setState({ isMobile: isMobile() })
    window.addEventListener('resize', this.updateViewport)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewport)
    eventEmitter.off()
  }

  updateViewport = () => {
    const mobile = isMobile()
    if (this.state.isMobile !== mobile) {
      this.setState({ isMobile: mobile })
    }
    if (!mobile && this.state.fullscreen) {
      this.setState({ fullscreen: false })
    }
  }
  render() {
    const { content, loading } = this.props
    let aosTimer = 400
    return (
      <div 
        className={styles.summarySection}
      >
        {content.edges.map((value, index) => {
          let color = undefined
          let backgroundColor = undefined
          let onClick = undefined
          aosTimer += 400
          switch (value.node.title.value) {
            case "question?":
              color = styles.purple
              backgroundColor = styles.purpleBackground
              onClick = this.openQuestion
              break;
            case "who?":
              color = styles.green
              backgroundColor = styles.greenBackground
              onClick = this.openWho
              break;
            case "where?":
              color = styles.blue
              backgroundColor = styles.blueBackground
              onClick = this.openWhere
              break;
            case "what?":
              color = styles.yellow
              backgroundColor = styles.yellowBackground
              onClick = this.openWhat
              break;
            default:
          }

          return <div key={index} className={styles.summarySubsection}
              data-aos="fade-up"
              data-aos-duration={aosTimer.toString()}>
            <div className={styles.summarySubsubsection} onClick={onClick}>
              <div className={styles.imageSection}>
                <div className={classNames(styles.image, backgroundColor)}>
                  <Img
                    critical
                    className={styles.image}
                    fluid={value.node.image.value.childImageSharp.fluid}
                    alt="Header picture"
                    onStartLoad={loading.register}
                    onLoad={loading.notify}
                  /></div>
                </div>
              <div className={styles.titleSection}>
                <div className={classNames(styles.title, color)}>{value.node.title.value.toUpperCase()}</div>
                <div className={styles.subtitle}>{value.node.subtitle.value.toUpperCase()}</div>
              </div>
              <div className={styles.lineSection}>
                <div className={styles.subsection}>
                  <div className={classNames(styles.line, color, backgroundColor)} />
                </div>
              </div>
              <div className={styles.bodySection}>
                <div className={styles.subsection}>
                  <div className={styles.body}>{value.node.body.value}</div>
                </div>
              </div>
              <div className={styles.lineSection}>
                <div className={styles.subsection}>
                  <div className={classNames(styles.line, color, backgroundColor)} />
                  <div className={classNames(styles.button, color)} onClick={onClick}>
                    <div className={styles.callToAction}>GO TO SECTION</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    )
  }

  openWhat = () => {
    if(this.state.isMobile) {
      navigate('/what')
    }else {
      eventEmitter.emit('openTray', 2)
    }
  }

  openWho = () => {
    if(this.state.isMobile) {
      navigate('/who')
    }else {
      eventEmitter.emit('openTray', 3)
    }
  }

  openWhere = () => {
    if(this.state.isMobile) {
      navigate('/where')
    }else {
      eventEmitter.emit('openTray', 4)
    }
  }

  openQuestion = () => {
    if(this.state.isMobile) {
      navigate('/question')
    }else {
      eventEmitter.emit('openTray', 5)
    }
  }
}

export default Summary

export const query = graphql`
fragment Summary on CockpitSummaryConnection {
    edges {
      node {
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title {
          value
        }
        subtitle {
          value
        }
        body {
          value
        }
        callToAction {
          value
        }
      }
    }
}
`
