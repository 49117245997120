import About from '../components/About'
import 'aos/dist/aos.css'
import AOS from 'aos'
import classNames from 'classnames'
import Footer from '../components/Footer';
import { graphql } from 'gatsby'
import Header from '../components/Header'
import Layout from '../components/Layout'
import React, { PureComponent } from 'react'
import Summary from '../components/Summary'
import Research from '../components/Research'
import withLoading from '../hocs/withLoading'

import styles from './styles.module.css'

class HomePage extends PureComponent {
  constructor(props) {
    super(props)
    this.aboutRef = React.createRef()
  }

  componentDidMount = () => {
    AOS.init({
      duration: 700,
      offset: 120,
    })
  }

  render() {
    const { pageContext, data, loading } = this.props;

    return (
      <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
        <div className={classNames(styles.background, styles.homePage)}>
          <div className={styles.section}>
            <Header
              content={data.cockpitHeader}
              scrollToRef={this.aboutRef}
              loading={loading}
            />
          </div>
          <div className={styles.section}>
            <About
              ref={this.aboutRef}
              content={data.cockpitAbout}
              loading={loading}
            />
          </div>
          <div className={styles.section}>
            <Research
              content={data.cockpitResearchSection}
              loading={loading}
            />
          </div>
          <div className={styles.section}>
            <Summary
              content={data.allCockpitSummary}
              loading={loading}
            />
          </div>
          <Footer/>
        </div>
      </Layout>
    )
  }
}

export default withLoading(HomePage)

export const query = graphql`
  query($locale: String) {
    cockpitHeader(
      cockpitId: { eq: "5d388fc074d1a2003b575442" }
    ) {
      ...Header
    }
    cockpitAbout(
      cockpitId: { eq: "5d38b5ed74d1a200684a5193" }
    ) {
      ...About
    }
    cockpitResearchSection(
      cockpitId: { eq: "5d487c5974d1a20105187993" }
    ) {
      ...Research
    }
    allCockpitSummary(
      filter: { lang: { eq: $locale } }
      ) {
      ...Summary
    }
  }
`
